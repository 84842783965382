<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col-12">
        <h1>Cargos</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control float-left border-right-0"
          v-on:keyup.enter="onSearch"
          v-model="searchTerm" placeholder="Pesquisar">
          <span class="input-group-append">
              <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
      <div class="col">
        <router-link to="cargos/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color" v-if="permissoes > 1">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <vue-good-table
          :columns="columns"
          :rows="filteredData"
          styleClass="vgt-table striped"
          :paginationOptions="$optionstable"
          >
          <div slot="emptystate" class="text-center font-weight-bold m-3">
            <span>Nenhum registro encontrado</span>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" class="float-right">
              <i class="fas fa-edit mr-3 btn btn-warning" @click="editRow(props.row.id)" v-if="permissoes > 1"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" @click="deleteRow(props.row)" v-if="permissoes > 2"></i>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import cargosRepository from './../../../services/api/funcionariosCargosRepository'
export default {
  name: 'FuncionariosCargos',
  components: {
    VueGoodTable
  },
  data () {
    return {
      isLoading: false,
      searchTerm: '',
      cargos: [],
      filteredData: [],
      columns: [
        {
          label: 'Nome',
          field: 'nome',
        },
        {
          label: 'Descrição',
          field: 'descricao',
        },
        {
          label: 'CBO',
          field: 'cbo',
        },
        {
          label: 'Ações',
          field: 'actions'
        }
      ],
      permissoes: [],
    }
  },
  created () {
    this.fetch()
    this.permissoes = this.$store.getters.permissoes['Treinamento']['Cargos']
  },
  methods: {
    fetch () {
      this.isLoading = true
      cargosRepository.listarCargos().then(response => {
        this.filteredData = this.cargos = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    },
    editRow (id) {
      this.$router.push({ name: 'FuncionariosCargosCadastro', query: { id: id } })
    },
    deleteRow (cargo) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover cargo',
        text: `Deseja deletar o cargo ${cargo.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarCargo(cargo)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Cargo removido com sucesso!'
          }).then(function () {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarCargo (cargo) {
      this.isLoading = true
      cargosRepository.deletarCargo(cargo.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    onSearch () {
      if (this.searchTerm == '') {
       this.filteredData = this.cargos
      } else {
        let filterKey = this.searchTerm.toLowerCase()
        let rest = this.cargos.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
        this.filteredData = rest
      }
    }
  }
}
</script>

<style>

</style>